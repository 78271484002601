import {Box, Stack, Typography} from '@mui/material';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useStyles} from './styles';
import Colors from '@app/themes/colors';

export const showEngagementErrors = (errorMsg: any) => {
  return toast.error(errorMsg, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const successToast = (successMsg: any) => {
  return toast.success(successMsg, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const HardSoftLabel = () => {
  const classes: any = useStyles();
  const styles = {
    hardSoftDiv: () => {
      return {
        position: 'absolute',
        bottom: '22px',
        marginLeft: '48px',
      };
    },
    hardConstraints: () => {
      return {
        width: '14px',
        height: '14px',
        backgroundColor: `${Colors.orange[300]}`,
        mr: 1,
        '@media (min-width: 600px) and (max-width: 899px)': {
          width: '12px',
          height: '12px',
        },
      };
    },
    softConstraints: () => {
      return {
        width: '14px',
        height: '14px',
        backgroundColor: `${Colors.blue[100]}`,
        mr: 1,
        '@media (min-width: 600px) and (max-width: 899px)': {
          width: '12px',
          height: '12px',
        },
      };
    },
  };
  return (
    <Stack direction={'row'} spacing={2} sx={styles.hardSoftDiv}>
      <Box sx={{display: 'flex', alignItems: 'center', mr: 4}}>
        <Box sx={styles.hardConstraints}></Box>
        <Typography className={classes.focusTitle}>Hard Constraint</Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={styles.softConstraints}></Box>
        <Typography className={classes.focusTitle}>Soft Constraint</Typography>
      </Box>
    </Stack>
  );
};
