import {makeStyles} from '@mui/styles';
import theme from '@app/themes';
import {fontFamilies} from '@app/themes/typography';
import Colors from '@app/themes/colors';

export const useStyles = makeStyles({
  stepCard: {
    height: '60vh',
    overflowY: 'scroll',
    padding: '32px',
    position: 'relative',
    '@media (min-width: 600px) and (max-width: 899px)': {
      padding: '20px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      padding: '26px',
    },
    '&::-webkit-scrollbar': {
      height: '6px',
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      '--webkitBorderRadius': '10px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      '--webkitBorderRadius': '10px',
      borderRadius: '10px',
      backgroundColor: '#c8c8c8',
      '--webkitBoxShadow': 'inset 0 0 6px #c8c8c8',
    },
  },
  cardHeight: {
    height: '578px',
  },

  marginBottomHeight: {
    marginBottom: '32px',
  },
  marginTopHeight: {
    marginTop: '32px',
  },
  screenTitle: {
    fontSize: '22.7px',
    fontWeight: 400,
    color: Colors.grey[200],
    marginBottom: '42.7px',
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '18.7px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '20.7px',
    },
  },
  boldedText: {
    fontFamily: fontFamilies.fontSemiBold,
  },
  textBoxTitle: {
    fontFamily: fontFamilies.fontSemiBold,
    fontSize: '16px',
    color: Colors.grey[200],
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '14px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '15px',
    },
  },
  dividerGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    height: '406px',
    '@media (min-width: 600px) and (max-width: 1023px)': {
      height: '363px',
    },
  },
  chipTitle: {
    color: Colors.grey[200],
    fontSize: '16px',
    fontFamily: fontFamilies.fontSemiBold,
  },
  selectTitle: {
    color: Colors.LIGHT_NAVY_BLUE,
    fontSize: '16px',
    fontFamily: fontFamilies.fontSemiBold,
    textTransform: 'lowercase',
    '&:hover': {
      backgroundColor: 'none !important',
    },
  },
  focusTitle: {
    color: Colors.grey[200],
    fontSize: '14px',
    fontFamily: fontFamilies.fontSemiBold,
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '12px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '13px',
    },
  },

  scrollRightIcon: {
    width: '32px',
    '@media (min-width: 600px) and (max-width: 899px)': {
      width: '24px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      width: '28px',
    },
  },
  focusStack: {alignItems: 'center'},
  focusMedBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  eligibleDiv: {
    width: '213.3px',
    height: '80px',
    backgroundColor: '#23a531',
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: '13.3px',
    borderBottomLeftRadius: '13.3px',
    '@media (min-width: 600px) and (max-width: 899px)': {
      width: '172.3px',
      height: '65px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      width: '172.3px',
      height: '65px',
    },
  },
  availBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '17.3px',
    marginRight: '32px',
    '@media (min-width: 600px) and (max-width: 899px)': {
      marginTop: '12.3px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      marginTop: '13.3px',
    },
  },
  eligibleText: {
    color: Colors.white,
    fontSize: '12.7px',
    fontFamiliy: fontFamilies.fontRegular,
    fontWeight: 400,
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '11px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '11.5px',
    },
  },
  eligibiltyCount: {
    color: Colors.white,
    fontSize: '24px',
    fontFamiliy: fontFamilies.fontBold,
    '@media (min-width: 600px) and (max-width: 899px)': {
      fontSize: '18px',
    },
    '@media (min-width: 900px) and (max-width: 1023px)': {
      fontSize: '21px',
    },
  },
  doctorsDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px 32px 0 32px',
  },
  adminDiv: {
    borderColor: Colors.LIGHT_NAVY_BLUE,
    border: '1px solid',
    padding: '14px 22.3px',
    borderRadius: '4px',
    '@media (min-width: 600px) and (max-width: 1023px)': {
      padding: '10px 10.3px',
    },
  },
  doctorSelectionDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectionTitle: {
    fontSize: '16px',
    fontFamily: fontFamilies.fontSemiBold,
    color: theme.colors.LIGHT_NAVY_BLUE,
  },

  chip: {
    color: theme.colors.LIGHT_NAVY_BLUE,
    backgroundColor: theme.colors.white,
    fontSize: '12.7px',
    fontStyle: 'normal',
    textAlign: 'center',
    border: 0,
    transition: '0.25s ease-out',
    '&:hover': {
      backgroundColor: 'none !important',
    },
    cursor: 'pointer',
  },

  activeTabs: {
    background: theme.colors.LIGHT_NAVY_BLUE,
    color: theme.colors.white,
  },

  stepperBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  //assign responsibilities screen

  step: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '16px',
    '&:last-child': {
      backgroundColor: '#23a531',
    },
  },

  responsibilitiesGrid: {
    alignItems: 'center',
  },
  vStepper: {position: 'relative'},
  circle: {
    backgroundColor: theme.colors.white,
    border: '3px solid #23a531',
    borderRadius: '100%',
    width: '20px',
    height: '20px',
    display: 'inline-block',
  },
  line: {
    top: '23px',
    left: '9px',
    height: '160%',
    position: 'absolute',
    borderLeft: '2px solid #8d8c9c',
    borderLeftStyle: 'dotted',
    '@media (min-width: 600px) and (max-width: 1023px)': {
      top: '23px',
      left: '7px',
      height: '185%',
    },
  },
  responsibilitiesTitle: {
    color: Colors.grey[200],
    fontSize: '16px !important',
    fontFamily: fontFamilies.fontSemiBold,
    '@media (min-width: 600px) and (max-width: 1023px)': {
      fontSize: '14px !important',
    },
  },
  doctorHeaders: {
    color: Colors.grey[200],
    fontSize: '16px',
    fontFamily: fontFamilies.fontSemiBold,
    '@media (min-width: 600px) and (max-width:899px)': {
      fontSize: '14px',
    },
    '@media (min-width: 900px) and (max-width:1023px)': {
      fontSize: '15px',
    },
  },
  tableHeaderBox: {
    padding: '17px 22.3px',
    '@media (min-width: 600px) and (max-width: 1023px)': {
      padding: '12px 10.3px',
    },
  },
  limitHeaders: {
    fontSize: '12.7px',
    fontFamily: fontFamilies.fontRegular,
    color: Colors.grey[200],
    opacity: 0.4,
    fontWeight: 400,
    '@media (min-width: 600px) and (max-width:899px)': {
      fontSize: '11.5px',
      textAlign: 'center',
    },
    '@media (min-width: 900px) and (max-width:1023px)': {
      fontSize: '12.5px',
      textAlign: 'center',
    },
  },
  regionTitles: {
    color: Colors.grey[200],
    fontSize: '16px',
    fontFamily: fontFamilies.fontRegular,
    fontWeight: 400,
    textAlign: 'start',
    wordWrap: 'break-word',
    '@media (min-width: 600px) and (max-width:899px)': {
      fontSize: '14px',
    },
    '@media (min-width: 900px) and (max-width:1023px)': {
      fontSize: '15px',
    },
  },
  makeScroll: {
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
  },
});
