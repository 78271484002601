import {useDispatch, useSelector} from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TableContainer,
  Typography,
} from '@mui/material';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownload';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {SelectableCollapsibleRow} from './sub-components/SelectableCollapsibleRow';
import {excelHeaders} from './utils';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {FLAT_EXCEL, MONTH_OBJECT} from '../../module-specific-utilities.types';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {useStyles} from '@app/screens/Styles/style';
import {
  bgColourFontBold,
  fitToColumnExcelJs,
  getTotal,
} from './ApproveExpenseDetailedTabVIew';
import {saveAs} from 'file-saver';
import Excel from 'exceljs';
import {useEffect, useState} from 'react';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {FormFooter} from './DCR-Report-Modal/sub-components/FormFooter';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  fetchDailyAllExpenseCreator,
  fetchDailyExpenseApprovalsDataCreator,
  fetchMonthlyAllExpenseCreator,
  fetchMonthlyExpenseCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {DailyReport} from './templates/dailyReport';
import MonthlyReport from './templates/monthlyReport';
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {strings} from '@app/common/strings';

interface PdfHeaderDetails {
  doc: any;
  header: string;
  name: string;
  sapcode: string;
  des: string;
  division: string;
  hq: string;
  month: string;
  expenseYear: string;
  expNum?: string | number;
  isMonthly?: boolean;
}

const tableHeaderMonthly: any = {
  expenseTypeName: 'Expense',
  amount: 'Amount',
  cLaimedAmount: 'Claimed Amount',
  changeReason: 'Change Reason',
  passedByFLM: 'Passed By Manager',
  managerChangeReason: 'Manager Change Reason',
  passedByAdmin: 'Passed By Admin',
  adminChangeReason: 'Admin Change Reason',
};
const tableHeaderMonthlyProp: string[] = Object.keys(tableHeaderMonthly);
const tableHeaderMonthlyVal: string[] = Object.values(tableHeaderMonthly);

const tableHeaderData: any = {
  expenseDay: 'Day',
  activityName: 'Activity',
  fromStation: 'From Station',
  toStation: 'To Station',
  noOfVisit: 'No Of Visit',
  workWith: 'Work With',
  hqType: 'HQ Type',
  distance: 'Distance',
  ta: 'Actual TA',
  taClaimed: 'TA Claimed',
  taChangeReason: 'TA Change Reason',
  managerPassedTA: 'TA Passed by Manager',
  managerChangeReasonTA: 'TA Change Reason by Manager',
  adminPassedDistance: 'Distance Passed by Admin',
  adminPassedTA: 'TA Passed by Admin',
  adminChangeReasonTA: 'TA Change Reason by Admin',
  da: 'Actual DA',
  dAclaimed: 'DA Claimed',
  dAchangeReason: 'DA Change Reason',
  managerPassedDA: 'DA Passed by Manager',
  managerPassedReasonDA: 'DA Change Reason by Manager',
  adminPassedDA: 'DA Passed by Admin',
  adminChangeReasonDA: 'DA Change Reason by Admin',
};
const tableHeaderDataProp: string[] = Object.keys(tableHeaderData);
const tableHeaderDataVal: string[] = Object.values(tableHeaderData);

export const getMonthInitial = (month: number, monthData: any) => {
  return monthData
    ?.find((monObj: any) => monObj?.key === month)
    ?.value?.substring(0, 3);
};

const resNestObj = (
  expenseApprovalsDaily: any,
  monthly?: boolean | undefined,
) => {
  return monthly
    ? expenseApprovalsDaily?.map((tableObj: any) =>
        tableHeaderMonthlyProp?.map((key: any) => tableObj[key]),
      )
    : expenseApprovalsDaily?.map((tableObj: any) =>
        tableHeaderDataProp?.map((key: any) => tableObj[key]),
      );
};

const getTotalForMonthly = (type: any, arr: any) => {
  return parseFloat(
    arr?.reduce((acc: number, curr: any) => acc + curr[type], 0)?.toFixed(2),
  );
};

const getTotalRow = (monthlyExpense: any) => ({
  expenseTypeName: 'Expense Total',
  amount: getTotalForMonthly('amount', monthlyExpense),
  cLaimedAmount: getTotalForMonthly('cLaimedAmount', monthlyExpense),
  changeReason: '',
  passedByFLM: getTotalForMonthly('passedByFLM', monthlyExpense),
  FLMChangeReason: '',
  passedByAdmin: getTotalForMonthly('passedByAdmin', monthlyExpense),
  adminChangeReason: '',
});

const getTATotal = (taDaTotal: any) => ({
  expenseTypeName: 'TA Total',
  cLaimedAmount: taDaTotal?.tA_Total_ClaimedAmount,
  passedByFLM: taDaTotal?.tA_Total_PassedByFLM,
  passedByAdmin: taDaTotal?.tA_Total_PassedByAdmin,
});

const getDATotal = (taDaTotal: any) => ({
  expenseTypeName: 'DA Total',
  cLaimedAmount: taDaTotal?.dA_Total_ClaimedAmount,
  passedByFLM: taDaTotal?.dA_Total_PassedByFLM,
  passedByAdmin: taDaTotal?.dA_Total_PassedByAdmin,
});

const getGrandTotal = (monthlyExpense: any, taDaTotal: any) => ({
  expenseTypeName: 'Grand Total',
  cLaimedAmount: (
    Number(getTotalForMonthly('cLaimedAmount', monthlyExpense)) +
    taDaTotal?.tA_Total_ClaimedAmount +
    taDaTotal?.dA_Total_ClaimedAmount
  ).toFixed(0),
  passedByFLM: (
    Number(getTotalForMonthly('passedByFLM', monthlyExpense)) +
    taDaTotal?.tA_Total_PassedByFLM +
    taDaTotal?.dA_Total_PassedByFLM
  ).toFixed(0),
  passedByAdmin: (
    Number(getTotalForMonthly('passedByAdmin', monthlyExpense)) +
    taDaTotal?.tA_Total_PassedByAdmin +
    taDaTotal?.dA_Total_PassedByAdmin
  ).toFixed(0),
});

const getDistanceTotal = (distanceTotal: any) => ({
  expenseTypeName: 'Distance Total',
  cLaimedAmount: distanceTotal?.distanceTotal,
  passedByFLM: distanceTotal?.distance_Total_PassedByFLM,
  passedByAdmin: distanceTotal?.distance_Total_PassedByAdmin,
});

const filteringMonthlyData = (arr: any, SelectedExpenseApprovalsUser: any) => {
  let mappedMonthlyExpenseData: any = [];
  let listOfHeaders: any = [];

  arr?.forEach((param: any) => {
    if (param.monthlyExpense === null) return;

    let mappedMonthlyData = param?.monthlyExpense?.map((rest: any) => ({
      ...rest,
      passedByAdmin:
        SelectedExpenseApprovalsUser?.expenseStatusId === 9
          ? 0
          : rest?.passedByAdmin,
      adminChangeReason:
        SelectedExpenseApprovalsUser?.expenseStatusId === 9
          ? ''
          : rest?.adminChangeReason,
    }));

    let headers = param?.header;

    mappedMonthlyData?.push(getTotalRow(mappedMonthlyData));
    mappedMonthlyData?.push(getTATotal(param?.taDaTotal));
    mappedMonthlyData?.push(getDATotal(param?.taDaTotal));
    mappedMonthlyData?.push(
      getGrandTotal(param?.monthlyExpense, param?.taDaTotal),
    );
    mappedMonthlyData?.push(getDistanceTotal(param?.distanceTotal));

    mappedMonthlyExpenseData?.push(mappedMonthlyData);
    listOfHeaders?.push(headers);
  });

  return {mappedMonthlyExpenseData, listOfHeaders};
};

const filteringMonthlyDataForDwnldAll = (
  arr: any,
  SelectedExpenseApprovalsUser: any,
) => {
  return filteringMonthlyData(arr, SelectedExpenseApprovalsUser);
};

const condionedValue = (cond: boolean | undefined, val1: any, val2: any) =>
  cond ? val1 : val2;

export const ApproveExpenseTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [isMonthly, setIsMonthly] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<any>('');
  const [sortedDirection, setSortedDirection] = useState<any>('');
  const [sortedList, setSortedList] = useState<any>([]);
  const [sortedHeader, setSortedHeader] = useState<any>('');

  const monthlyExpenseData = useSelector(
    approveExpenseStateSelector?.getMonthlyExpense(),
  );
  const dataForDownloadAll = useSelector(
    approveExpenseStateSelector?.getDownloadAllMonthlyData(),
  );
  const expenseApprovalsLoading = useSelector(
    approveExpenseStateSelector?.getExpenseApprovalsLoading(),
  );
  const expenseApprovalsData: any = useSelector(
    approveExpenseStateSelector?.getExpenseApprovalsData(),
  );
  const SelectedExpenseApprovalsUser = useSelector(
    approveExpenseStateSelector?.getSelectedExpenseApprovalsUser(),
  );

  const selectedExpenseApprovalsData: any[] = useSelector(
    approveExpenseStateSelector?.getSelectedExpenseApprovalsData(),
  );
  const flattenedExpenseApprovalsData = useSelector(
    approveExpenseStateSelector?.getFlattenedExpenseApprovalsData(),
  );
  const expenseApprovalsDailyData: any[] = useSelector(
    approveExpenseStateSelector?.getSingleUserDailyExpense(),
  );
  const allSelected = useSelector(
    approveExpenseStateSelector?.getIsExpenseAprrovalsAllSelected(),
  );
  const msg = useSelector(approveExpenseStateSelector?.getTableMessage());

  const monthData = useSelector(approveExpenseStateSelector?.getMonthData());
  const allDailyData = useSelector(
    approveExpenseStateSelector?.getDailyAllData(),
  );

  const adminExpenseSelectedRows =
    useSelector(
      approveExpenseStateSelector?.getSelectedExpenseApprovalsData(),
    ) ?? [];

  const data = () => {
    let datagrid = expenseApprovalsDailyData[0]?.row?.map((rest: any) => ({
      ...rest,
    }));
    return datagrid;
  };

  const getTotalsForDaily = (expenseData: any, index = 0) => ({
    expenseDetailId: null,
    dcrId: null,
    staffPositionId: null,
    staffUserId: null,
    expenseDate: null,
    expenseDay: null,
    expenseMonth: null,
    expenseYear: null,
    activity: null,
    fromStationId: null,
    fromStation: null,
    toStation: null,
    hqType: 'TOTAL',
    distance: data()?.length && getTotal('distance', expenseData[index]?.row),
    ta: data()?.length && getTotal('ta', expenseData[index]?.row),
    taClaimed: data()?.length && getTotal('taClaimed', expenseData[index]?.row),
    taChangeReason: null,
    managerPassedTA:
      data()?.length && getTotal('managerPassedTA', expenseData[index]?.row),

    managerChangeReasonTA: null,
    adminPassedDistance:
      data()?.length &&
      getTotal('adminPassedDistance', expenseData[index]?.row),
    adminPassedTA:
      data()?.length && getTotal('adminPassedTA', expenseData[index]?.row),

    adminChangeReasonTA: null,
    da: data()?.length && getTotal('da', expenseData[index]?.row),
    dAclaimed: data()?.length && getTotal('dAclaimed', expenseData[index]?.row),
    dAchangeReason: null,
    managerPassedDA:
      data()?.length && getTotal('managerPassedDA', expenseData[index]?.row),
    managerPassedReasonDA: null,
    hqTypeAdmin: null,
    adminPassedDA:
      data()?.length && getTotal('adminPassedDA', expenseData[index]?.row),
    adminChangeReasonDA: null,
    workWith: null,
    noOfDoctors:
      data()?.length && getTotal('noOfDoctors', expenseData[index]?.row),
    noofChemists:
      data()?.length && getTotal('noofChemists', expenseData[index]?.row),
    remarks: null,
    dcrDayCode: null,
    taRate: null,
    billPath: null,
    dahqAmount: null,
    daExAmount: null,
    daOutAmount: null,
    isWeekOff: null,
    isHoliday: null,
    isMultipleTaDaExists: null,
    wsfaRemarks: null,
    id: expenseApprovalsDailyData?.length + 1,
    noOfVisit: null,
  });

  let tot = getTotalsForDaily(expenseApprovalsDailyData, 0);
  let expenseApprovalsDaily: any = [...(data() || []), tot];

  const {mappedMonthlyExpenseData} = filteringMonthlyData(
    monthlyExpenseData,
    SelectedExpenseApprovalsUser,
  );
  const [mappedMonthlyData] = mappedMonthlyExpenseData;

  const isDisableAllBtnForDwnldnExcel = isMonthly
    ? !resNestObj(mappedMonthlyData, true)
    : !resNestObj(allDailyData[0]?.row, false);

  const onCloseClick = () => {
    setOpen(false);
    setIsMonthly(false);
  };

  const getMultipleSelectedUser = () =>
    selectedExpenseApprovalsData?.map((user: any) => ({
      sapCode: user?.sapCode,
      year: user?.expenseYear,
      month: user?.expenseMonth,
      staffPositionId: user?.staffPositionId,
    }));

  const getUserDataDailyAll = () => {
    setOpen(true);
    const multipleUsers = getMultipleSelectedUser();
    dispatch(
      fetchDailyAllExpenseCreator({
        multipleUsers,
        isFromExpenseTable: true,
      }),
    );
  };

  const onOpenMonthly = () => {
    setIsMonthly(true);
    setOpen(true);
    const multipleUsers = getMultipleSelectedUser();
    dispatch(fetchMonthlyAllExpenseCreator(multipleUsers));
  };

  const getData = () => {
    if (adminExpenseSelectedRows?.length > 0) {
      return adminExpenseSelectedRows;
    } else {
      return flattenedExpenseApprovalsData;
    }
  };
  const downloadExcel = () => {
    const wb = new Excel.Workbook();
    const data =
      getData()?.map((row: FLAT_EXCEL) => {
        return {
          expenseMonth:
            monthMock()?.find(
              (month: MONTH_OBJECT) => month?.key === row?.expenseMonth,
            )?.value +
            ',' +
            row?.expenseYear,
          divisionName: row?.divisionName,
          adminComment: row?.adminComment,
          sapCode: row?.sapCode,
          staffName: row.staffName,
          designationName: row?.designationName,
          hqName: row?.hqName,
          stateName: row?.stateName,
          adminApprovedTaAmount: row?.adminApprovedTaAmount,
          adminApprovedDaAmount: row?.adminApprovedDaAmount,
          adminApprovedMiscAmount: row?.adminApprovedMiscAmount,
          dacustomTotalAmount: row?.dacustomTotalAmount,
          tacustomTotalAmount: row?.tacustomTotalAmount,
          miscCustomTotalAmount: row?.miscCustomTotalAmount,
          managerApprovedTaAmount: row?.managerApprovedTaAmount,
          managerApprovedDaAmount: row?.managerApprovedDaAmount,
          managerApprovedMiscAmount: row?.managerApprovedMiscAmount,
          totalClaimedAmount: row?.totalClaimedAmount,
          managerApprovedTotalAmount: row?.managerApprovedTotalAmount,
          adminApprovedTotalAmount: row?.adminApprovedTotalAmount,
          subordinates: JSON.stringify(row?.subordinates),
        };
      }) ?? [];
    const ws = wb?.addWorksheet(strings.ExpenseApproval);
    let headers = [
      'Month,Year',
      'Division',
      'Rejected Reason',
      'Employee Code',
      'Staff Name',
      'Designation',
      'HQ',
      'State',
      'Passed TA Amt Admin',
      'Passed DA Amt Admin',
      'Passed Misc Amt Admin',
      'Claimed DA Amt',
      'Claimed TA Amt',
      'Claimed Misc Amt',
      'Total TA Passed Manager',
      'Total DA Passed Manager',
      'Total Misc Passed Manager',
      'Total Amt Claimed',
      'Total Amt Passed Manager',
      'Total Amt Passed Admin',
      'Subordinates',
    ];

    ws?.addRow(headers);
    data?.forEach((rowData: any) => {
      ws.addRow(Object.values(rowData));
    });

    bgColourFontBold(ws);
    fitToColumnExcelJs(ws, data);
    wb?.xlsx
      ?.writeBuffer()
      ?.then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = 'admin-expense.xlsx';
        saveAs(blob, fileName);
      })
      ?.catch((error: any) => {
        console.error(error);
      });
  };

  const handleOnClickSelectAll = (event: any) => {
    if (event?.target?.checked) {
      dispatch(
        approveExpenseStateActions?.setSelectedExpenseApprovalsData(
          flattenedExpenseApprovalsData,
        ),
      );
      dispatch(
        approveExpenseStateActions?.setIsExpenseAprrovalsAllSelected(true),
      );
      return;
    }
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsData([]));
    dispatch(
      approveExpenseStateActions?.setIsExpenseAprrovalsAllSelected(false),
    );
  };
  const handleNext = () => {
    dispatch(approveExpenseStateActions?.setMiscEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions?.setDailyEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions?.setDailyEditedRowColorIndexArr1([]));
    dispatch(
      approveExpenseStateActions?.setApproveExpenseScreen({
        screen: 2,
        backBtn: true,
      }),
    );
    dispatch(approveExpenseStateActions?.setPageNo(0));
  };
  useEffect(() => {
    if (selectedExpenseApprovalsData[0]) {
      const params = [
        {
          sapCode: selectedExpenseApprovalsData[0]?.sapCode,
          year: selectedExpenseApprovalsData[0]?.expenseYear,
          month: selectedExpenseApprovalsData[0]?.expenseMonth,
          staffPositionId: selectedExpenseApprovalsData[0]?.staffPositionId,
        },
      ];
      dispatch(
        fetchDailyExpenseApprovalsDataCreator({
          multipleUsers: params,
          isFromExpenseTable: true,
        }),
      );
      dispatch(
        approveExpenseStateActions?.setSelectedExpenseApprovalsUser(
          selectedExpenseApprovalsData[0],
        ),
      );
      dispatch(fetchMonthlyExpenseCreator(params));
    }
  }, [selectedExpenseApprovalsData]);

  const handleChange = (event: any) => {
    dispatch(approveExpenseStateActions?.setMonthlyExpense([]));
    const {sapCode, expenseYear, expenseMonth, staffPositionId} =
      event?.target?.value ??{};
    if (event?.target?.value) {
      dispatch(
        approveExpenseStateActions.setSelectedExpenseApprovalsUser(
          event?.target?.value,
        ),
      );

      const params = [
        {sapCode, year: expenseYear, month: expenseMonth, staffPositionId},
      ];
      dispatch(
        fetchDailyExpenseApprovalsDataCreator({
          multipleUsers: params,
          isFromExpenseTable: true,
        }),
      );

      dispatch(fetchMonthlyExpenseCreator(params));
    }
  };
  const isDisable = (): boolean => selectedExpenseApprovalsData?.length !== 0;

  const downloadAllForPdf = () => {
    const sortBasedOnExNum: any = [...dataForDownloadAll].sort(
      (a: any, b: any) => +a?.header?.expNumber - +b?.header?.expNumber,
    );
    const {mappedMonthlyExpenseData, listOfHeaders} =
      filteringMonthlyDataForDwnldAll(
        sortBasedOnExNum,
        SelectedExpenseApprovalsUser,
      );

    isMonthly
      ? generatePDFMonthly(
          mappedMonthlyExpenseData,
          SelectedExpenseApprovalsUser,
          true,
          listOfHeaders,
          monthData,
          4,
          isMonthly,
          [],
          sortBasedOnExNum,
        )
      : generatePDF(
          allDailyData,
          SelectedExpenseApprovalsUser,
          true,
          monthData,
          getTotalsForDaily,
        );
  };

  const generatingExcelData = (
    rowData: any,
    isMonthly: boolean | undefined,
  ) => {
    if (!isMonthly) {
      const monthDate =
        (rowData?.expenseMonth &&
          rowData?.expenseYear &&
          `${getMonthInitial(rowData?.expenseMonth, monthData)}-${
            rowData?.expenseYear % 100
          }`) ||
        '';
      return {
        monthName: monthDate,
        staffName: rowData?.staffName,
        hqName: rowData?.hqName,
        sapCode: rowData?.sapCode,
        Day: rowData?.expenseDay,
        Activity: rowData?.activityName,
        FromStation: rowData?.fromStation,
        ToStation: rowData?.toStation,
        NoOfVisit: rowData?.noOfVisit,
        WorkWith: rowData?.workWith,
        HqType: rowData?.hqType,
        Distance: rowData?.distance,
        ActualTA: rowData?.ta,
        TAClaimed: rowData?.taClaimed,
        TAChangeReason: rowData?.taChangeReason,
        TAPassedbyFLM: rowData?.managerPassedTA,
        TAChangeReasonbyFLM: rowData?.managerChangeReasonTA,
        DistancePassedbyAdmin: rowData?.adminPassedDistance,
        TAPassedbyAdmin: rowData?.adminPassedTA,
        TAChangeReasonbyAdmin: rowData?.adminChangeReasonTA,
        ActualDA: rowData?.da,
        DAClaimed: rowData?.dAclaimed,
        DAchangeReason: rowData?.dAchangeReason,
        DAPassedbyFLM: rowData?.managerPassedDA,
        DAChangeReasonbyFLM: rowData?.managerPassedReasonDA,
        DAPassedbyAdmin: rowData?.adminPassedDA,
        DAChangeReasonbyAdmin: rowData?.adminChangeReasonDA,
      };
    } else {
      const monthDate =
        (rowData?.month &&
          rowData?.year &&
          `${getMonthInitial(rowData?.month, monthData)}-${
            rowData?.year % 100
          }`) ||
        '';
      return {
        monthName: monthDate,
        staffName: rowData?.staffName,
        hqName: rowData?.hqName,
        sapCode: rowData?.sapCode,
        Expense: rowData?.expenseTypeName,
        Amount: rowData?.amount,
        ClaimedAmount: rowData?.cLaimedAmount,
        ChangeReason: rowData?.changeReason,
        PassedByFLM: rowData?.passedByFLM,
        FLMChangeReason: rowData?.managerChangeReason,
        PassedByAdmin: +rowData?.passedByAdmin,
        AdminChangeReason: rowData?.adminChangeReason,
      };
    }
  };

  const gettingAllData = (expenseArr: any) =>
    expenseArr?.map((rowData: any) => generatingExcelData(rowData, isMonthly));

  // To download the excel for monthly data
  const downloadExcelForMonthly = (
    isAll: boolean | undefined,
    isMonthly?: boolean,
  ) => {
    const headers = [
      'Month-YY',
      'Name',
      'Hq',
      'Emp Code',
      'Expense',
      'Amount',
      'Claimed Amount',
      'Change Reason',
      'Passed By Manager',
      'Manager Change Reason',
      'Passed By Admin',
      'Admin Change Reason',
    ];
    const additionalHeader = ['Month-YY', 'Name', 'Hq', 'Emp Code'];
    const dailyHeader = [...additionalHeader, ...tableHeaderDataVal];
    const workbook = new Excel.Workbook();
    const excelHeader = condionedValue(isMonthly, headers, dailyHeader);
    let wsBooks: any = '';

    const processMonthlyData = (
      expenseData: any,
      sheetName: string,
      index: number,
    ) => {
      if (!wsBooks) {
        wsBooks = workbook?.addWorksheet(sheetName);
        wsBooks?.addRow(excelHeader);
      }

      expenseData?.forEach((rowData: any, i: number) => {
        wsBooks?.addRow(Object.values(rowData));
      });

      wsBooks?.addRow([]);
      if (isMonthly) {
        wsBooks.getRow(wsBooks.actualRowCount - 4 + index).font = {bold: true};
        wsBooks.getRow(wsBooks.actualRowCount - 1 + index).font = {bold: true};
        wsBooks.getRow(wsBooks.actualRowCount + index).font = {bold: true};
      } else {
        wsBooks.getRow(wsBooks.actualRowCount + index).font = {bold: true};
      }
      bgColourFontBold(wsBooks);
      fitToColumnExcelJs(wsBooks, [...expenseData, ...['', '', '', '']]);
    };

    if (isAll) {
      const sortBasedOnExNum: any = [...dataForDownloadAll].sort(
        (a: any, b: any) => +a?.header?.expNumber - +b?.header?.expNumber,
      );
      const {mappedMonthlyExpenseData} = filteringMonthlyDataForDwnldAll(
        sortBasedOnExNum,
        SelectedExpenseApprovalsUser,
      );

      const expenseData = condionedValue(
        isMonthly,
        mappedMonthlyExpenseData,
        allDailyData,
      );

      expenseData?.forEach((monthlyData: any, index: number) => {
        const sheetName = isMonthly
          ? strings.moduleSpecificUtilities.MonthlyReport
          : strings.moduleSpecificUtilities.DailyReport;

        const expenseDataForTable = isMonthly
          ? monthlyData
          : [...monthlyData.row, getTotalsForDaily(allDailyData, index)];
        processMonthlyData(
          gettingAllData(expenseDataForTable),
          sheetName,
          index,
        );
      });
    } else {
      const monthlyOrDailyArr = condionedValue(
        isMonthly,
        mappedMonthlyData,
        expenseApprovalsDaily,
      );

      const expenseApprovalsDailyData = monthlyOrDailyArr?.map((rowData: any) =>
        generatingExcelData(rowData, isMonthly),
      );

      const sheetName = `${
        SelectedExpenseApprovalsUser?.sapCode
      }-${getMonthInitial(
        SelectedExpenseApprovalsUser?.expenseMonth,
        monthData,
      )}-${SelectedExpenseApprovalsUser?.expenseYear}`;
      processMonthlyData(expenseApprovalsDailyData, sheetName, 0);
    }
    workbook?.xlsx
      ?.writeBuffer()
      ?.then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = `${
          isMonthly
            ? strings.moduleSpecificUtilities.ApproveExpenseMonthly
            : strings.moduleSpecificUtilities.ApproveExpenseDaily
        }.xlsx`;
        saveAs(blob, fileName);
      })
      ?.catch((error: any) => {
        console.error(error);
      });
  };

  /* This method is to sort the list based on the header */
  const sortingBasedOnHeaderClicked = (header: any, sortDir: string) => {
    let sortedType = strings.asc;
    if (sortDir === strings.desc) {
      setSortedList([]);
      setSortedDirection('');
      setSortedHeader('');
      return;
    }
    if (sortedColumn === header?.key) {
      sortedType = strings.asc ? strings.desc : strings.asc;
      setSortedColumn('');
    } else {
      setSortedColumn(header?.key);
      setSortedHeader(header?.key);
      sortedType = strings.asc;
    }
    const sortedResult = [...expenseApprovalsData]?.sort((a: any, b: any) => {
      const valueA =
        typeof a[header?.key] === 'string'
          ? a[header?.key].toLowerCase()
          : a[header?.key];
      const valueB =
        typeof b[header?.key] === 'string'
          ? b[header?.key].toLowerCase()
          : b[header?.key];
      if (sortedType === strings.asc) {
        if (valueA < valueB) {
          return -1;
        }
        return valueA > valueB ? 1 : 0;
      } else if (valueA > valueB) {
        return -1;
      } else {
        return valueA < valueB ? 1 : 0;
      }
    });

    setSortedDirection(sortedType);
    setSortedList(sortedResult);
  };

  return (
    <Container maxWidth={false}>
      <button
        data-testid="triggeringButton"
        className={'hiddenButton'}
        onClick={() => {
          handleNext();
          handleOnClickSelectAll({target: {value: true}});
        }}
      />{' '}
      {expenseApprovalsLoading && (
        <Box className={classes.justifyContentcenterflex} data-testid="loading">
          <CircularProgress />
        </Box>
      )}
      {msg &&
        !expenseApprovalsLoading &&
        expenseApprovalsData?.length === 0 && (
          <Box className={classes?.mt}>
            <Typography>No records to show.</Typography>
          </Box>
        )}
      {!expenseApprovalsLoading && expenseApprovalsData?.length > 0 && (
        <>
          <TableContainer component={Paper} className={classes?.tableContainer}>
            <Table aria-label="simple table">
              <TableHead className={classes?.tableHead}>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell padding="checkbox"></TableCell>
                  {(excelHeaders || [])?.map((row: any) => (
                    <TableCell
                      onClick={() =>
                        sortingBasedOnHeaderClicked(row, sortedDirection)
                      }
                      key={row.label}
                      className={classes?.tableCellStyle}>
                      {row?.label}
                      {sortedHeader === row?.key &&
                        (sortedDirection === strings.asc ? (
                          <ArrowUpwardIcon className={classes?.sortIcon} />
                        ) : (
                          sortedDirection === strings.desc && (
                            <ArrowDownwardIcon className={classes?.sortIcon} />
                          )
                        ))}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{fontSize: '12px'}}>
                {(
                  (sortedList?.length ? sortedList : expenseApprovalsData) || []
                )?.map((row: any) => (
                  <SelectableCollapsibleRow
                    key={row?.expenseHistoryId}
                    row={row}
                    collapseIconPadding={0}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}>
            <Typography>
              <Checkbox
                data-testid="checkkBox"
                checked={allSelected}
                onClick={event => handleOnClickSelectAll(event)}
              />
              {strings.SelectAll}
            </Typography>
            <Box sx={{p: 2}}>
              <Button
                data-testid="daily-report"
                disabled={!isDisable()}
                sx={{m: 1}}
                variant="contained"
                onClick={getUserDataDailyAll}
                endIcon={<CloudDownloadRoundedIcon />}>
                {strings.DailyExpReport}
              </Button>
              <Button
                data-testid="monthly-report"
                disabled={!isDisable()}
                sx={{m: 1}}
                variant="contained"
                onClick={onOpenMonthly}
                endIcon={<CloudDownloadRoundedIcon />}>
                {strings.MonthlyExpReport}
              </Button>
              <Button
                data-testid="convert-excel"
                sx={{m: 1}}
                variant="contained"
                endIcon={<CloudDownloadRoundedIcon />}
                onClick={downloadExcel}>
                {strings.ConvertintoExcel}
              </Button>
              <Button
                sx={{m: 1}}
                disabled={selectedExpenseApprovalsData?.length === 0}
                variant="contained"
                onClick={handleNext}>
                {strings.Next}
              </Button>
            </Box>
          </Stack>
        </>
      )}
      <CustomModal
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={onCloseClick}
        header={<Box display={'flex'} justifyContent="space-between"></Box>}
        footer={<FormFooter onCloseClick={onCloseClick} />}>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{mt: 2}}>
            <Box>
              <Button
                data-testid="download-pdf"
                disabled={isDisableAllBtnForDwnldnExcel}
                variant="contained"
                onClick={() =>
                  isMonthly
                    ? generatePDFMonthly(
                        mappedMonthlyData,
                        SelectedExpenseApprovalsUser,
                        false,
                        [],
                        monthData,
                        monthlyExpenseData[0]?.header?.expNumber,
                        isMonthly,
                        monthlyExpenseData,
                      )
                    : generatePDF(
                        expenseApprovalsDaily,
                        SelectedExpenseApprovalsUser,
                        false,
                        monthData,
                        tot,
                      )
                }>
                {strings.Download}
              </Button>
              <Button
                data-testid="download-pdf-all"
                disabled={
                  isMonthly
                    ? !filteringMonthlyDataForDwnldAll(
                        dataForDownloadAll,
                        SelectedExpenseApprovalsUser,
                      )?.mappedMonthlyExpenseData?.length
                    : isDisableAllBtnForDwnldnExcel
                }
                sx={{ml: 2}}
                variant="contained"
                onClick={downloadAllForPdf}>
                {strings.DownloadAll}
              </Button>
              <Button
                data-testid="export-excel"
                disabled={isDisableAllBtnForDwnldnExcel}
                sx={{ml: 2}}
                variant="contained"
                onClick={() => downloadExcelForMonthly(false, isMonthly)}>
                {strings.ExporttoExcel}
              </Button>
              <Button
                data-testid="export-excel-all"
                disabled={
                  isMonthly
                    ? !filteringMonthlyDataForDwnldAll(
                        dataForDownloadAll,
                        SelectedExpenseApprovalsUser,
                      )?.mappedMonthlyExpenseData?.length
                    : isDisableAllBtnForDwnldnExcel
                }
                sx={{ml: 2}}
                variant="contained"
                onClick={() => downloadExcelForMonthly(true, isMonthly)}>
                {strings.ExporttoExcelALL}
              </Button>
            </Box>
            <Box sx={{minWidth: 300, mt: 2}}>
              <FormControl size="small" fullWidth>
                <InputLabel id="select-staff-dropdown" sx={{fontSize: '12px'}}>
                  {strings.ModuleSpecificUtilitie.approveExpense.selectStaff}
                </InputLabel>
                <Select
                  id="select-staff-dropdown"
                  value={SelectedExpenseApprovalsUser || ''}
                  onChange={handleChange}
                  label={
                    strings.ModuleSpecificUtilitie.approveExpense.selectStaff
                  }
                  sx={{fontSize: '11px'}}>
                  {selectedExpenseApprovalsData?.map((data: any) => (
                    <MenuItem value={data} key={data?.staffPositionId}>
                      {data?.expNumber && isMonthly
                        ? ` ${data?.expNumber}-${
                            data?.staffName
                          }-${getMonthInitial(data?.expenseMonth, monthData)}-${
                            data?.expenseYear
                          }`
                        : `${data?.staffName}-${getMonthInitial(
                            data?.expenseMonth,
                            monthData,
                          )}-${data?.expenseYear}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>
          {isMonthly ? (
            <MonthlyReport
              data={monthlyExpenseData}
              header={tableHeaderMonthlyVal}
              tableData={resNestObj(mappedMonthlyData, true)}
              selectedUser={SelectedExpenseApprovalsUser}
              expenseNumber={monthlyExpenseData[0]?.header?.expNumber}
              getMonthInitial={getMonthInitial}
              monthData={monthData}
            />
          ) : (
            <DailyReport
              header={tableHeaderDataVal}
              tableData={resNestObj(expenseApprovalsDaily, false)}
              selectedUser={SelectedExpenseApprovalsUser}
              getMonthInitial={getMonthInitial}
              monthData={monthData}
            />
          )}
        </Box>
      </CustomModal>
    </Container>
  );
};

const generatePDF = (
  expenseApprovalsDaily: any,
  SelectedExpenseApprovalsUser: any,
  isAll?: boolean,
  monthData?: any,
  getDailyTotals?: any,
) => {
  const dailyPdfHeader = strings.moduleSpecificUtilities.dailyExpenseReport;
  if (isAll) {
    multipleUserDetailsDownload(
      expenseApprovalsDaily,
      SelectedExpenseApprovalsUser,
      monthData,
      false,
      getDailyTotals,
      [],
    );
    return;
  }
  const doc = new jsPDF('l', 'mm', 'a4');
  const header = tableHeaderDataVal;
  const data = resNestObj(expenseApprovalsDaily);
  const marginHeight = 48;
  const name = SelectedExpenseApprovalsUser?.staffName;
  const des = SelectedExpenseApprovalsUser?.designationName;
  const hq = SelectedExpenseApprovalsUser?.hqName;
  const month = getMonthInitial(
    SelectedExpenseApprovalsUser?.expenseMonth,
    monthData,
  );

  const expenseYear = SelectedExpenseApprovalsUser?.expenseYear;
  const sapcode = SelectedExpenseApprovalsUser?.sapCode;
  const division = SelectedExpenseApprovalsUser?.divisionName;
  const options = {
    margin: {top: marginHeight, left: 10},
    didDrawPage: getBeforePageContent({
      doc,
      header: dailyPdfHeader,
      name,
      sapcode,
      des,
      division,
      hq,
      month,
      expenseYear,
    }),
    createdRow: (row: any) => {
      row.styles.lineWidth = 0.3;
    },
    drawCell: () => {
      doc?.setFontSize(4);
    },
    startY: marginHeight,
  };
  const columnWidths = header?.map((_, colIndex) => {
    const maxContentWidth = Math.max(
      doc?.getStringUnitWidth(header[colIndex]) * 1.5,
      ...data??[].map((row: any) => {
        const cellContent = String(row[colIndex]);
        return doc?.getStringUnitWidth(cellContent) * 4;
      }),
    );
    return maxContentWidth;
  });

  const columnStyles = columnWidths?.reduce((acc: any, width, colIndex) => {
    acc[colIndex] = {cellWidth: width > 16 ? 13.2 : 11};
    return acc;
  }, {});

  (doc as any).autoTable({
    head: [header],
    body: data,
    theme: 'grid',
    headStyles: getHeaderStyles(6),
    styles: {
      fontSize: 5,
      halign: 'center',
      textColor: [1, 1, 1, 1],
      wordBreak: 'break-word',
    },
    willDrawCell: (row: any, data: any, body: any) => {
      if (row?.row?.index === row?.table?.body?.length - 1) {
        // When it's my special row
        doc.setFontSize(7);
        doc.setTextColor(0, 0, 0);
      }
    },
    columnStyles,
    ...options,
  });

  const date = new Date();
  const minutes = date?.getMinutes();
  const dateStr = `${date?.toLocaleDateString()} ${date?.getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  const pageCount = (doc as any)?.internal?.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc?.setFontSize(8);
    doc?.text(dateStr, 14, doc?.internal?.pageSize?.height - 10);
    doc?.setPage(i);
    doc?.text(
      `Page ${i} of ${pageCount}`,
      264,
      doc?.internal?.pageSize?.height - 10,
    );
  }
  doc?.save(strings.DailyExpenseReportpdf);
};

const getDADistanceTotalBorderDisgn = (data: any) => {
  if (
    data?.row?.raw[0] === strings.DATotal ||
    data?.row?.raw[0] === strings.DistanceTotal
  ) {
    data.cell.styles.lineWidth = {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
    };
  }
};

const getGrandTotalBorderDisgn = (data: any) => {
  if (data.row.raw[0] === strings.GrandTotal) {
    data.cell.styles.lineWidth = {
      bottom: 0.4,
      top: 0.2,
      left: 0,
      right: 0,
    };
    data.cell.styles.fontStyle = 'bold';
  }
};

const getTATotalBorderDisgn = (data: any) => {
  if (data?.row?.raw[0] === strings.TATotal) {
    data.cell.styles.lineWidth = {
      bottom: 0,
      top: 0.4,
      left: 0,
      right: 0,
    };
  }
};

const didParseCell = (data: any) => {
  getDADistanceTotalBorderDisgn(data);
  getGrandTotalBorderDisgn(data);
  getTATotalBorderDisgn(data);
  if (
    data?.row?.raw[0] === strings.DistanceTotal ||
    data?.row?.raw[0] === strings.ExpenseTotal
  ) {
    data.cell.styles.fontStyle = 'bold';
  }
};

const addingPage = (i: number, expenseApprovalsDaily: any, doc: any) => {
  if (i < expenseApprovalsDaily?.length - 1) {
    doc?.addPage();
  }
  // intentionally returning undefined
  return undefined;
};

const multipleUserDetailsDownload = (
  expenseApprovalsDaily: any,
  listOfHeader: any,
  monthData?: any,
  isMonthly?: any,
  getDailyTotals?: any,
  allMonthlyExpense?: any,
) => {
  const doc = new jsPDF('l', 'mm', 'a4');
  const header = condionedValue(
    isMonthly,
    tableHeaderMonthlyVal,
    tableHeaderDataVal,
  );
  const marginHeight = 48;
  const docTitle = condionedValue(
    isMonthly,
    strings.moduleSpecificUtilities.monthlyExpenseReport,
    strings.moduleSpecificUtilities.dailyExpenseReport,
  );

  for (let i = 0; i < expenseApprovalsDaily?.length; i++) {
    const allData = condionedValue(
      isMonthly,
      expenseApprovalsDaily,
      expenseApprovalsDaily[i]?.row,
    );
    const headers = condionedValue(
      isMonthly,
      listOfHeader,
      expenseApprovalsDaily[i]?.header,
    );
    const data = resNestObj(
      isMonthly
        ? allData[i]
        : [...allData, getDailyTotals(expenseApprovalsDaily, i)],
      isMonthly,
    );
    const name = condionedValue(
      isMonthly,
      headers[i]?.staffName,
      headers?.staffName,
    );
    const des = condionedValue(
      isMonthly,
      headers[i]?.designation,
      headers?.designation,
    );
    const hq = condionedValue(isMonthly, headers[i]?.hqName, headers?.hqName);
    const dateArr = !isMonthly && headers?.monthName?.split('-');
    const month = getMonthInitial(
      condionedValue(isMonthly, headers[i]?.month, parseInt(dateArr[0], 10)),
      monthData,
    );
    const expenseYear = condionedValue(
      isMonthly,
      headers[i]?.year,
      parseInt(dateArr[1], 10),
    );
    const sapcode = condionedValue(
      isMonthly,
      headers[i]?.sapCode,
      headers?.sapCode,
    );
    const division = condionedValue(
      isMonthly,
      headers[i]?.divisionName,
      headers?.divName,
    );

    const options = {
      margin: {top: marginHeight, left: 10},
      didDrawPage: getBeforePageContent({
        doc,
        header: docTitle,
        name,
        sapcode,
        des,
        division,
        hq,
        month,
        expenseYear,
        expNum: isMonthly
          ? allMonthlyExpense[i]?.header?.expNumber ?? ''
          : null,
        isMonthly,
      }),
      createdRow: (row: any) => {
        row.styles.lineWidth = 0.5;
      },
      drawCell: () => {
        doc?.setFontSize(4);
      },
      startY: marginHeight,
    };
    const columnWidths = (header || [])?.map((_: any, colIndex: any) => {
      const maxContentWidth = Math.max(
        doc?.getStringUnitWidth(header[colIndex]) * 1.5,
        ...data??[].map((row: any) => {
          const cellContent = String(row[colIndex]);
          return doc?.getStringUnitWidth(cellContent) * 4;
        }),
      );
      return maxContentWidth;
    });
    const columnStyles = columnWidths?.reduce(
      (acc: any, width: any, colIndex: any) => {
        acc[colIndex] = {cellWidth: 0};
        return acc;
      },
      {},
    );

    (doc as any)?.autoTable({
      head: [header],
      body: data || [],
      theme: 'grid',
      headStyles: getHeaderStyles(6),
      styles: {
        fontSize: 5,
        halign: 'center',
        textColor: [1, 1, 1, 1],
        wordBreak: 'break-word',
      },
      willDrawCell: (row: any, data: any, body: any) => {
        if (
          isMonthly &&
          row.row.section === 'body' &&
          (row?.row?.raw[0] === strings.ExpenseTotal ||
            row?.row?.raw[0] === strings.GrandTotal ||
            row?.row?.raw[0] === strings.DistanceTotal)
        ) {
          doc?.setTextColor(0, 0, 0);
        }
      },
      columnStyles,
      ...options,
      didParseCell: didParseCell,
    });
    addingPage(i, expenseApprovalsDaily, doc);
  }
  const pageCount = (doc as any)?.internal?.getNumberOfPages();
  const date = new Date();
  const minutes = date?.getMinutes();
  const dateStr = `${date?.toLocaleDateString()} ${date?.getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  footerForPDF(doc, pageCount, allMonthlyExpense, dateStr, isMonthly);
  doc?.save(`${docTitle}.pdf`);
};

const generatePDFMonthly = (
  expenseApprovalsDaily: any,
  SelectedExpenseApprovalsUser?: any,
  isDownloadAll?: boolean,
  listOfHeader?: any,
  monthData?: any,
  expenseNumber?: any,
  ...rest: any
) => {
  const [isMonthly, monthlyExpenseData, allMonthlyExpense] = rest;
  const monthlyPdfHeader = strings.moduleSpecificUtilities.monthlyExpenseReport;
  if (isDownloadAll) {
    multipleUserDetailsDownload(
      expenseApprovalsDaily,
      listOfHeader,
      monthData,
      isMonthly,
      [],
      allMonthlyExpense,
    );
    return;
  }
  const doc = new jsPDF('l', 'mm', 'a4');
  const header = tableHeaderMonthlyVal;
  const data = resNestObj(expenseApprovalsDaily, true);
  const marginHeight = 48;
  const name = SelectedExpenseApprovalsUser?.staffName;
  const des = SelectedExpenseApprovalsUser?.designationName;
  const hq = SelectedExpenseApprovalsUser?.hqName;
  const month = getMonthInitial(
    SelectedExpenseApprovalsUser?.expenseMonth,
    monthData,
  );
  const expenseYear = SelectedExpenseApprovalsUser?.expenseYear;
  const sapcode = SelectedExpenseApprovalsUser?.sapCode;
  const division = SelectedExpenseApprovalsUser?.divisionName;
  const expNum = expenseNumber === null ? '' : expenseNumber;
  const options = {
    margin: {top: marginHeight, left: 10},
    didDrawPage: getBeforePageContent({
      doc,
      header: monthlyPdfHeader,
      name,
      sapcode,
      des,
      division,
      hq,
      month,
      expenseYear,
      expNum,
      isMonthly,
    }),
    createdRow: (row: any) => {
      row.styles.lineWidth = 0.5;
    },
    drawCell: () => {
      doc.setFontSize(4);
    },
    startY: marginHeight,
  };
  const columnWidths = header?.map((_, colIndex) => {
    const maxContentWidth = Math.max(
      doc.getStringUnitWidth(header[colIndex]) * 1.5,
      ...data??[].map((row: any) => {
        const cellContent = String(row[colIndex]);
        return doc?.getStringUnitWidth(cellContent) * 4;
      }),
    );
    return maxContentWidth;
  });

  const columnStyles = columnWidths?.reduce((acc: any, width, colIndex) => {
    acc[colIndex] = {cellWidth: 0};
    return acc;
  }, {});

  (doc as any)?.autoTable({
    head: [header],
    body: data,
    theme: 'grid',
    headStyles: getHeaderStyles(6),
    styles: {
      fontSize: 5,
      halign: 'center',
      textColor: [1, 1, 1, 1],
      wordBreak: 'break-word',
    },
    willDrawCell: (row: any, data: any, body: any) => {
      if (
        row?.row?.section === 'body' &&
        (row?.row?.index === expenseApprovalsDaily?.length - 2 ||
          row?.row?.index === expenseApprovalsDaily?.length - 1 ||
          row?.row?.index === expenseApprovalsDaily?.length - 5)
      ) {
        doc?.setTextColor(0, 0, 0);
      }
    },
    columnStyles,
    ...options,
    didParseCell: didParseCell,
  });
  const pageCount = (doc as any)?.internal?.getNumberOfPages();
  const date = new Date();
  const minutes = date?.getMinutes();
  const dateStr = `${date?.toLocaleDateString()} ${date?.getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;

  footerForPDF(doc, pageCount, monthlyExpenseData, dateStr, isMonthly);
  doc.save(strings.MonthlyExpenseReportpdf);
};

const footerForPDF = (
  doc: any,
  pageCount: number,
  monthlyExpenseData: any,
  dateStr: any,
  isMonthly: boolean | undefined,
) => {
  const filteredMonthlyExp = monthlyExpenseData?.filter(
    (monthlyDetails: any) =>
      monthlyDetails?.distanceTotal &&
      monthlyDetails?.footer &&
      monthlyDetails?.monthlyExpense,
  );
  for (let i = 1; i <= pageCount; i++) {
    const approvedOnDate = filteredMonthlyExp[i - 1]?.footer?.approvedOn
      ? moment
          .utc(filteredMonthlyExp[i - 1]?.footer?.approvedOn)
          .local()
          .format('DD/MM/YYYY, h:mm a')
      : '';

    const passOnDate = filteredMonthlyExp[i - 1]?.footer?.passedOn
      ? moment
          .utc(filteredMonthlyExp[i - 1]?.footer?.passedOn)
          .local()
          .format('DD/MM/YYYY, h:mm a')
      : '';

    doc?.setPage(i);
    doc?.setFontSize(8);
    isMonthly &&
      doc?.text(
        `${strings.moduleSpecificUtilities.passedBy} - ${
          filteredMonthlyExp[i - 1]?.footer?.passedBy || ''
        }`,
        14,
        doc?.internal?.pageSize?.height - 9,
      );
    isMonthly &&
      doc?.text(
        `${strings.moduleSpecificUtilities.passedOn} - ${passOnDate}`,
        84,
        doc?.internal?.pageSize?.height - 9,
      );
    isMonthly &&
      doc?.text(
        `${strings.moduleSpecificUtilities.approvedBy} - ${
          filteredMonthlyExp[i - 1]?.footer?.approvedBy || ''
        }`,
        154,
        doc?.internal?.pageSize?.height - 9,
      );
    isMonthly &&
      doc?.text(
        `${strings.moduleSpecificUtilities.approvedOn} - ${approvedOnDate}`,
        224,
        doc?.internal?.pageSize?.height - 9,
      );
    doc?.text(dateStr, 14, doc?.internal?.pageSize?.height - 2);
    doc.text(
      `Page ${i} of ${pageCount}`,
      264,
      doc?.internal?.pageSize?.height - 2,
    );
  }
};

export const getHeaderStyles = (fontSize: number) => {
  return {
    lineWidth: 0.5,
    lineColor: [0, 0, 0, 0.1],
    fillColor: [255, 255, 255],
    textColor: [1, 1, 1, 1],
    fontSize: fontSize,
    fontStyle: 'normal',
    fontWeight: 'bold',
    halign: 'center',
    wordBreak: 'break-word',
  };
};

const getBeforePageContent = (pdfHeaders: PdfHeaderDetails) => {
  const lineSpacing = 6;
  pdfHeaders?.doc?.text(pdfHeaders?.header, 120, 12);
  pdfHeaders?.doc?.setFontSize(11);
  pdfHeaders?.doc?.text(
    `${strings.moduleSpecificUtilities.staffName}      ${pdfHeaders?.name} ${strings.moduleSpecificUtilities.sapCode}${pdfHeaders?.sapcode}`,
    10,
    15 + lineSpacing,
  );
  pdfHeaders?.doc?.text(
    `${strings.moduleSpecificUtilities.designation}    ` + pdfHeaders?.des,
    10,
    15 + 2 * lineSpacing,
  );
  pdfHeaders?.doc?.text(
    `${strings.moduleSpecificUtilities.division}             ` +
      pdfHeaders?.division,
    10,
    15 + 3 * lineSpacing,
  );
  pdfHeaders?.doc?.text(
    `${strings.moduleSpecificUtilities.hq}                       ` +
      pdfHeaders?.hq,
    10,
    15 + 4 * lineSpacing,
  );
  pdfHeaders?.doc?.text(
    `${strings.moduleSpecificUtilities.month}                ` +
      pdfHeaders?.month +
      '-' +
      pdfHeaders?.expenseYear,
    10,
    15 + 5 * lineSpacing,
  );
  pdfHeaders?.doc?.setFontSize(20);
  pdfHeaders?.isMonthly &&
    pdfHeaders?.doc?.text(
      `${strings.moduleSpecificUtilities.expNum}` + pdfHeaders?.expNum,
      200,
      15 + 2.5 * lineSpacing,
    );
  // intentionally returing undefined
  return undefined;
};
